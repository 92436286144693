import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';

import SEO from '../components/seo';

import imgHero from '../assets/img/heroes/vie_privee.jpg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Mentions légales | Agence Digitale | MDS Digital Agency"
      description="Grâce à cette déclaration de confidentialité, nous vous informons de la manière dont nous, MDS Digital Agency, traitons et utilisons vos données personnelles."
      keywords="mentions légales"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Mentions légales</h2>
      <h4>MDS PROCONSULT SPRL</h4>
      <p>Siège social : Boulevard Initialis, 7/1 7000 Mons</p>
      <p>Numéro de téléphone : +32 493 41 83 82</p>
      <p>E-mail de contact : info@mds-digitalagency.be</p>
      <p>Numéro de TVA : BE 0538.820.152</p>
      <p>Numéro BCE : 0538.820.152</p>
      <p>Adresse d’accessibilité : http://www.mds-digitalagency.be/</p>
      <p>
        Coordonnées de l’hébergeur : AWS – Amazon Web Services EMEA SARL - 38, Avenue John F.
        Kennedy L-1855 Luxembourg
      </p>

      <p>
        Le tribunal compétent en cas de litige est celui de Mons et la législation applicable est la
        loi belge.
      </p>
      <p>
        Les éléments présents sur ce site internet sont la propriété intellectuelle de l’éditeur du
        site. Ils ne peuvent en aucun cas être repris, complètement ou partiellement sans
        autorisation.
      </p>
      <p>
        L’éditeur du site n’est pas responsable en cas d’impossibilité d’accessibilité au site
        internet (maintenance ou problème informatique), des informations disponibles, des liens
        hypertextes (menant vers des sites externes), des commentaires d’internautes.
      </p>
    </article>

    <Footer />
  </div>
);
